import { Component, OnInit, ɵgetDebugNode__POST_R3__, Input, OnChanges } from '@angular/core';
import * as L from "leaflet";
import 'leaflet.markercluster';
import { MapService } from '../map.service';
import 'leaflet.heat/dist/leaflet-heat.js';
import 'leaflet.webgl-temperature-map';
import { HttpClient } from "@angular/common/http";
import * as d3 from 'd3';

import * as geotiff from 'leaflet-geotiff/leaflet-geotiff';
import * as plotty from 'leaflet-geotiff/leaflet-geotiff-plotty';

import 'leaflet.fullscreen';
// import 'leaflet-sidebar-v2'

import 'plotty';
import * as LeafletGeotiff from "leaflet-geotiff";
import 'geotiff-layer-leaflet/dist/geotiff-layer-leaflet';
import 'geotiff-layer-leaflet/src/geotiff-layer-leaflet-plotty';
import 'geotiff-layer-leaflet/src/geotiff-layer-leaflet-vector-arrows';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import 'leaflet.markercluster.layersupport';
import { FormControl } from '@angular/forms';
// import 'leaflet-sidebar'
// import 'Leaflet.fullscreen'

// import '@ngageoint/leaflet-geopackage' 



@Component({
  selector: 'app-heat-map',
  templateUrl: './heat-map.component.html',
  styleUrls: ['./heat-map.component.css']
})
export class HeatMapComponent implements OnInit {
  CountryCtrl = new FormControl(['South Africa', 'Namibia', 'Botswana']);
  // CountryCtrl['value'] =;
  fontStyle?: string;
  private _opened: boolean = false;
  // ngOnChanges(changes: SimpleChanges) {
  //   this.initMap()
  // }

  showFiller = false;
  showSpinner = true;
  WeatherStations = L.layerGroup([]);
  stationLayers = L.layerGroup([]);
  WeatherStations1;
  private _toggleSidebar() {
    this._opened = !this._opened;

  }

  public fullscreenOptions: { [key: string]: any; } = {
    position: 'topleft',
    title: 'View Fullscreen',
    titleCancel: 'Exit Fullscreen',
  };

  Select = '';
  Date = '';
  DataName = '';
  MaxValue = 0;
  TotWeatherStations = 0;

  SACoords;



  SelectOptions = ['Average Air Pressure', 'Average Cloud Cover', 'Day Light Intergral', 'Average Dew Point',
    'Max Dew Point', 'Min Dew Point', 'Global Radiation', 'Average Relative Humidity', 'Max Relative Humidity', 'Min Relative Humidity',
    'Picto Code', 'Precipitation (Sum)', 'Precipitation (Max)', 'Precipitation (Min)', 'Shower Probability', 'Snow Fraction', 'Average Soil Moisture',
    'Max Soil Moisture', 'Min Soil Moisture', 'Average Soil Temperature', 'Max Soil Temperature', 'Min Soil Temperature', 'Sunshine Duration', 'Average Air Temperature Felt',
    'Max Air Temperature Felt', 'Min Air Temperature Felt', 'Average Air Temperature', 'Max Air Temperature', 'Min Air Temperature', 'Average Surface Temperature', 'Max Surface Temperature'
    , 'Min Surface Temperature', 'Thunderstorm Probability', 'Max Windgust', 'Average Windspeed', 'Max Windspeed', 'Min Windspeed', 'Early Blight Vlaue', 'Smith Blight Score', 'Smith Blight Score Average', 'Smith Blight Score Peak'];

  private map;
  private tempMap;


  SA_Coords = [];

  public circles = L.markerClusterGroup({
    disableClusteringAtZoom: true,
    showCoverageOnHover: false,
    zoomToBoundsOnClick: true
  });


  constructor(private ms: MapService, private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.initMap();



    this.httpClient.get("assets/SAMAP.json").subscribe(data => {

      for (var x in data['features'][0]['geometry']['coordinates']) {
        for (var y in data['features'][0]['geometry']['coordinates'][x][0]) {

          // console.log(data['features'][0]['geometry']['coordinates'][x][0][y]);

          this.SA_Coords.push(data['features'][0]['geometry']['coordinates'][x][0][y].reverse());
        }

      }


    });

  }

  SelectName;



  PlotWeatherStation() {
    var AllCircles;
    var stations = [];
    this.ms.getLocation().subscribe(data => {
      var LocationData = data;


      for (var d in LocationData) {

        var index = LocationData[d]['index'];
        var lat = LocationData[d]['latitude'];
        var lng = LocationData[d]['longitude'];
        var name = LocationData[d]['name'];
        var group = LocationData[d]['country'];
        if (name.toLowerCase() != '' && group.toLowerCase() != '') {
          this.TotWeatherStations += 1;
          var circle = L.circle([lat, lng], {
            color: "blue",
            fillColor: "#f03",
            fillOpacity: 0.5,
            radius: 50.0
          });
          stations.push(circle);
          this.WeatherStations.addLayer(circle);

        }

        var list = "<p>" + name + "</p>";
        circle.bindPopup(list);
        circle.on('mouseover', function (e) {
          this.openPopup();
        });
        circle.on('mouseout', function (e) {
          this.closePopup();
        });


      }
      this.stationLayers = L.layerGroup(stations);
      // console.log(this.stationLayers);
    });

  }


  DataChange(e) {

    //console.log(e.value);
    this.SelectName = e.value;
    //console.log(this.DataName);

    if (this.Date != '' && this.DataName != '') {
      this.UpdateHeatMap(this.Date, this.DataName);
    }
  }

  DateChange(e) {
    //console.log(e.value);
    var date = e.value;
    var day = date.getDate();
    var month = (date.getMonth() + 1);
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    var year = date.getFullYear();
    this.Date = year + '-' + month + '-' + day + ' 00:00:00';
    //console.log(this.Date);

    if (this.Date != '' && this.DataName != '') {
      this.UpdateHeatMap(this.Date, this.DataName);
    }
  }


  ResizeMap() {
    this.initMap();

    this.UpdateHeatMap(this.Date, this.DataName);
  }

  setView(latlng) {
    this.map.setView(latlng, 6);
    // //console.log(this.circles)
    var i = 0;
    this.circles.eachLayer(function (layer) {
      if (layer.getLatLng().lat == latlng[0]
        && layer.getLatLng().lng == latlng[1]
      ) {
        layer.openPopup();
      }
    });
  }

  openSideBar() {
    // this.sidebar.toggle()
  }
  private initMap() {



    this.PlotWeatherStation();

    var CartoDB_Voyager = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 19
    });
    this.map = L.map('map',
      {
        attributionControl: true,
        center: [-25.75, 24.34],
        zoom: 5,
        layers: []
      });

    this.map.addLayer(CartoDB_Voyager);
    // this.map.dragging.disable();
    var overlayMaps1 = {
      "Countires": this.stationLayers,
    };

    this.map.addLayer(this.WeatherStations);
    var overlayMaps = {
      "Weather stations": this.WeatherStations,
    };


    // L.control.layers({},overlayMaps).addTo(this.map);



    const osm = new L.TileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
    var Esri_WorldImagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    });





    this.map.addLayer(CartoDB_Voyager);
    L.control.layers({}, overlayMaps, { collapsed: false }).addTo(this.map);



  }


  tiffUrl;


  public UpdateHeatMap(date, DataName) {
    // console.log(date, DataName);
    this.showSpinner = true;
    //console.log('Tot Weather stations');
    //console.log(this.TotWeatherStations);
    let i = 0;
    this.map.eachLayer((layer) => {
      i += 1;
      if (i > 535) {
        //console.log(layer);
        //console.log(layer);
        this.map.removeLayer(layer);
      }


    });


    this.map.remove();
    this.initMap();




    const url = this.tiffUrl;
    //console.log(url);


    this.ms.getRaster(DataName, date).subscribe((res) => {
      // console.log(res['path']);

      // 'http://localhost:5000/' + res['path'];
      //console.log(this.tiffUrl);

      const url = 'https://getafix.southafricanorth.cloudapp.azure.com/syng2/' + res['path'];

      this.drawRasters(url, res['max'], res['min']);

    }
    );
  }


  // onCountryChange(countries) {
  //   console.log(countries);
  //   console.log(this.map);
  // }

  drawRasters(url, max, min) {

    // console.log(min, max);
    const Botswana = LeafletGeotiff.leafletGeotiff(
      url + '/Bots.tiff',
      {
        band: 0,
        name: this.DataName,
        opacity: 0.5,
        // clip: this.SA_Coords,
        clearBeforeMove: false,

        renderer: new LeafletGeotiff.LeafletGeotiff.Plotty({
          displayMin: min - 0.5,
          displayMax: parseFloat(max),
          // arrowSize: 20,
          clampLow: false,
          clampHigh: true,
          colorScale: 'rainbow'
        })
      }
    ).addTo(this.map);
    // console.log(this.SA_Coords);
    const SA = LeafletGeotiff.leafletGeotiff(
      url + '/SA.tiff',
      {
        band: 0,
        name: this.DataName,
        opacity: 0.5,
        clearBeforeMove: false,
        // clip: this.SA_Coords,
        renderer: new LeafletGeotiff.LeafletGeotiff.Plotty({
          displayMin: min - 0.5,
          displayMax: parseFloat(max),
          clampLow: true,
          clampHigh: true,
          colorScale: 'rainbow'

        })
      }
    );
    // console.log(SA);
    SA.addTo(this.map);

    const Nam = LeafletGeotiff.leafletGeotiff(
      url + '/Nam.tiff',
      {
        band: 0,
        name: this.DataName,
        opacity: 0.5,
        clearBeforeMove: false,
        renderer: new LeafletGeotiff.LeafletGeotiff.Plotty({
          displayMin: min - 0.5,
          displayMax: parseFloat(max),
          // arrowSize: 20,
          clampLow: true,
          clampHigh: true,
          colorScale: 'rainbow'
        })
      }
    ).addTo(this.map);

    const Full = LeafletGeotiff.leafletGeotiff(
      url + '/Full.tiff',
      {
        band: 0,
        name: this.DataName,
        opacity: 1,
        // clip: this.SA_Coords,
        renderer: new LeafletGeotiff.LeafletGeotiff.Plotty({
          displayMin: min - 1,
          displayMax: parseFloat(max),
          // arrowSize: 20,
          clampLow: true,
          clampHigh: true,
          colorScale: 'viridis'
        })
      }
    );
    let i = 0;
    this.map.eachLayer(function () { i += 1; });


    // console.log(this.SA_Coords);
    var popup = L.popup();
    this.map.addEventListener('mousemove', function (e) {


      if (11 < e.latlng.lng && e.latlng.lng < 33) {

        if (Botswana.getValueAtLatLng(e.latlng.lat, e.latlng.lng) != null && Botswana.getValueAtLatLng(e.latlng.lat, e.latlng.lng) != -9999) {
          var value = parseFloat(Full.getValueAtLatLng(e.latlng.lat, e.latlng.lng)).toFixed(2);
          popup.setContent("" + value);
          Botswana.bindPopup(popup);
          popup.setLatLng(e.latlng).openOn(Botswana);
        }
        if (SA.getValueAtLatLng(e.latlng.lat, e.latlng.lng) != null) {
          var value = parseFloat(Full.getValueAtLatLng(e.latlng.lat, e.latlng.lng)).toFixed(2);
          popup.setContent("" + value);
          SA.bindPopup(popup);
          popup.setLatLng(e.latlng).openOn(SA);
        }

        if (Nam.getValueAtLatLng(e.latlng.lat, e.latlng.lng) != null && Nam.getValueAtLatLng(e.latlng.lat, e.latlng.lng) != -9999) {
          var value = parseFloat(Full.getValueAtLatLng(e.latlng.lat, e.latlng.lng)).toFixed(2);
          popup.setContent("" + value);
          Nam.bindPopup(popup);
          popup.setLatLng(e.latlng).openOn(Nam);
        }
      }


      // console.log(Nam.getColourbarDataUrl());
    });

    this.map.addEventListener('mouseout', function (e) {
      popup.popupclose;



    });





    var overlayMaps = {
      "Botswana": Botswana,
      "Namibia": Nam,
      "South Africa": SA,
    };
    L.control.layers({}, overlayMaps, { collapsed: false }).addTo(this.map);
    this.showSpinner = false;
  }


}
