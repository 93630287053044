<!-- <mat-card style="width: 98%; height: 90vh; margin: auto"> -->
<div style="margin-left: 2%; margin-right: 2%; display: flex">
  <mat-form-field appearance="fill" style="width: 30vw">
    <mat-label>Data Point</mat-label>
    <input
      (focus)="onFocus()"
      type="text"
      placeholder="Pick a data point"
      aria-label="Data Point"
      matInput
      [formControl]="myControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      (optionSelected)="DataChange($event)"
    >
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div class="date">
    <button mat-button (click)="PrevDate()">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <h1 style="position: relative; margin: 2%">
      {{ heatmapDate }}
    </h1>
    <button mat-button (click)="NextDate()">
      <mat-icon>chevron_right</mat-icon>
    </button>
    <button mat-button (click)="Picker.open()">
      <mat-icon>calendar_today</mat-icon>
    </button>
    <mat-form-field style="width: 1vw; margin-left: 2%; display: hidden">
      <input
        style="display: hidden; color: #ffffff"
        matInput
        [matDatepicker]="Picker"
        disabled
        (dateChange)="DateChange($event.value)"
      />
      <!-- <mat-datepicker-toggle matSuffix [for]="Picker"></mat-datepicker-toggle> -->
      <mat-datepicker
        style="color: #ffffff"
        #Picker
        disabled="false"
      ></mat-datepicker>
    </mat-form-field>
  </div>
</div>
<app-heat-map style="height: 90%"></app-heat-map>
<!-- </mat-card> -->
