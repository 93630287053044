import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { HeatMapComponent } from '../heat-map/heat-map.component';

import { MapService } from '../map.service';
import { Observable } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { GraphDataService } from '../graph-data.service';
import { map, startWith } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { MatDatepicker } from '@angular/material/datepicker';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;



// import 'rxjs/add/operator/toPromise';
export interface Locations {
  index: number,
  lat: string,
  lng: string,
  name: string,
}

export interface Graph {
  index: number,
  startDate: Date,
  endDate: Date,
  name: String,
  // endDateFormCtrl: FormControl,
  // startDateFormCtrl: FormControl;
}

export interface location {
  index: number,
  name: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM',
  },
  display: {
    dateInput: 'DD/MM',
    monthLabel: 'MM,',
    // dateA11yLabel: 'LL',
    monthA11yLabel: 'MMM',
  },
};

@Component({
  selector: 'app-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.css'],
})
export class ParentComponent implements AfterViewInit, OnInit {
  choice = 1;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  graphArr: Graph[] = [];
  graphLocations: location[] = [{ index: -1, name: '' }];
  startDateFormCtrl = new FormControl(new Date());
  endDateFormCtrl = new FormControl(new Date());
  startDate = new Date();
  endDate = new Date();
  StartDate = '';
  // Select = 'Aberdeen';

  GraphStartDate = new Date();
  GraphEndDate = new Date();
  EndDate = '';
  index = 0;
  PlaceName = '';
  // filteredOptions: Observable<Locations[]>;
  myControl = new FormControl();
  LocationData: Locations[] = [];
  // myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  @ViewChild(HeatMapComponent) heatmap;

  heatmapDate = new Date().toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });


  @ViewChild(MatAutocompleteTrigger) trigger;
  onFocus() {
    this.trigger._onChange("");
    this.trigger.openPanel();
  }


  displayFn(loc: Locations): string {
    return loc && loc.name ? loc.name : '';
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }


  constructor(private ms: MapService, private graph: GraphDataService) { }


  // Must add in a function to get the available dates for the heat map 


  getLocations() {
    this.graph.getLocation().subscribe(data => {
      // console.log(data);
      var LocationData = data;
      for (var d in LocationData) {

        var index = LocationData[d]['id'];
        var lat = LocationData[d]['latitude'];
        var lng = LocationData[d]['longitude'];
        var name = LocationData[d]['name'];
        if (name.toLowerCase() != 'none') {

          var temp: Locations = {
            index: index,
            lat: lat,
            lng: lng,
            name: name,
          };
          this.LocationData.push(temp);


        }


      }

    });
  }

  ngOnInit(): void {

    this.options = [];
    this.ms.getMetrics().subscribe((res) => {
      for (var i in res) {


        this.options.push(res[i]);
      }

    });
    this.getLocations();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );


    this.myControl.setValue('Temperature (Max)');

    this.DataName = 'Temperature (Max)';


    var date = new Date();
    var day = date.getDate();
    var month = (date.getMonth() + 1);
    if (month < 10) {
      var DisMonth = '0' + month;
    }
    else {
      var DisMonth = '' + month;
    }

    if (day < 10) {
      var disDay = '0' + day;
    }
    else {
      var disDay = '' + day;
    }
    var year = date.getFullYear();
    this.Date = year + '-' + DisMonth + '-' + disDay;

    // console.log(this.Date);

  }
  ngAfterViewInit(): void {
    this.heatmap.UpdateHeatMap(this.Date, 'Temperature (Max)');


  }




  Date = '';

  SelectName = '';
  DataName = '';

  PrevDate() {
    var prevDate = new Date(this.heatmapDate);
    prevDate.setDate(prevDate.getDate() - 1);
    this.DateChange(prevDate);

  }
  NextDate() {
    // this.heatmapDate.setDate(this.heatmapDate.value)

    var nextDate = new Date(this.heatmapDate);
    nextDate.setDate(nextDate.getDate() + 1);
    // this.heatmapDate = nextDate.toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    this.DateChange(nextDate);
  }

  DateChange(e) {
    var date = e;
    this.heatmapDate = e.toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    var day = date.getDate();
    var month = (date.getMonth() + 1);
    if (month < 10) {
      var DisMonth = '0' + month;
    }
    else {
      var DisMonth = '' + month;
    }

    if (day < 10) {
      var disDay = '0' + day;
    }
    else {
      var disDay = '' + day;
    }
    var year = date.getFullYear();
    this.Date = year + '-' + DisMonth + '-' + disDay;
    console.log(this.Date);

    if (this.Date != '' && this.DataName != '') {
      this.heatmap.Date = this.Date;
      this.heatmap.DataName = this.DataName;
      this.heatmap.UpdateHeatMap(this.Date, this.DataName);
    }
  }


  DataChange(e) {
    this.DataName = e.option.value;

    if (this.Date != '' && this.DataName != '') {
      this.heatmap.Date = this.Date;
      this.heatmap.DataName = this.DataName;
      this.heatmap.UpdateHeatMap(this.Date, this.DataName);
    }
  }

}
