import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { Http, Response } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class GraphDataService {


  constructor(private http: HttpClient) { }

  postdataURL = 'http://localhost:8080/api/SynData1:';
  getLocation() {
    var url = 'https://getafix.southafricanorth.cloudapp.azure.com/syng2/api/locations';
    return this.http.get(url);

  }
  // , graphStartDate, graphEndDate, StartDate, EndDate



  async getData(index, graphStartDate, graphEndDate, startDate, endDate) {
    console.log('getData');
    var url = 'https://getafix.southafricanorth.cloudapp.azure.com/syng2/api/getGraph/' + index;
    // console.log(url);
    var obj = {};
    await this.http.get(url).subscribe(function (data) {
      // console.log(data);
      var count = 0;
      for (var d in data) {
        var keys = Object.keys(data[d]);
        var compareDate = new Date(data[d]['date']);
        if (compareDate >= startDate && compareDate <= endDate) {

          if (compareDate >= new Date(graphStartDate) && compareDate <= new Date(graphEndDate)) {
            if (count == 0) {
              for (var key in keys) {
                // console.log(keys[key]);
                obj[keys[key]] = [data[d][keys[key]]];
              }
            }
            else {
              for (var key in keys) {
                obj[keys[key]].push(data[d][keys[key]]);
              }
            }
            count += 1;
          }
          else {
            if (count == 0) {
              for (var key in keys) {
                console.log(keys[key]);
                obj[keys[key]] = [null];
              }
            }
            else {
              for (var key in keys) {
                obj[keys[key]].push(null);
              }
            }
            count += 1;
          }
        }


        // console.log(keys);

        // console.log(obj);


      }

    });
    return obj;

  }


  getDates(index) {
    // This Function is to return the Start and End Date of the DataSet
    var url = 'https://getafix.southafricanorth.cloudapp.azure.com/syng2/api/getAvailableDates/' + index;
    console.log(url);
    return this.http.get(url);

  }

  getMetrics() {
    var url = 'https://getafix.southafricanorth.cloudapp.azure.com/syng2/api/getMetrics';
    return this.http.get(url);
  }
  getGraph2(index, startDate: string, endDate: string) {
    var url = 'https://getafix.southafricanorth.cloudapp.azure.com/syng2/api/getGraph2/' + index + '?' + startDate + '&' + endDate;
    console.log(url);
    return this.http.get(url);
  }
  getGraphData(index) {
    // console.log('getData');
    var url = 'https://getafix.southafricanorth.cloudapp.azure.com/syng2/api/getGraph/' + index;
    // console.log(url);
    // var obj = {};
    return this.http.get(url);



  }

}
