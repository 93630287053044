<br />
<div
  *ngIf="showSpinner"
  style="
    width: 98vw;
    height: 80vh;
    position: absolute;
    z-index: 1000;
    background-color: rgba(99, 96, 96, 0.3);
  "
>
  <mat-spinner style="top: 40%; left: 50%"></mat-spinner>
</div>

<div
  class="map-frame"
  style="
    height: 80vh;
    width: 98vw;
    display: relative;
    margin: 0 auto;
    z-index: 100;
  "
>
  <link
    rel="stylesheet"
    href="https://unpkg.com/leaflet@1.1.0/dist/leaflet.css"
    integrity="sha512-wcw6ts8Anuw10Mzh9Ytw4pylW8+NAD4ch3lqm9lzAsTxg0GFeJgoAtxuCLREZSC5lUXdVyo/7yfsqFjQ4S+aKw=="
    crossorigin=""
  />
  <script
    src="https://unpkg.com/leaflet@1.1.0/dist/leaflet.js"
    integrity="sha512-mNqn2Wg7tSToJhvHcqfzLMU6J4mkOImSPTxVZAdo+lcPlk+GhZmYgACEe0x35K7YzW1zJ7XyJV/TT1MrdXvMcA=="
    crossorigin=""
  ></script>

  <link
    rel="stylesheet"
    href="https://leaflet.github.io/Leaflet.markercluster/dist/MarkerCluster.css"
  />
  <link
    rel="stylesheet"
    href="https://leaflet.github.io/Leaflet.markercluster/dist/MarkerCluster.Default.css"
  />
  <script src="https://leaflet.github.io/Leaflet.markercluster/dist/leaflet.markercluster-src.js"></script>

  <script src="https://unpkg.com/leaflet@1.3.1/dist/leaflet.js"></script>
  <script src="https://unpkg.com/geotiff@0.4.1/dist/main.js"></script>
  <script src="https://unpkg.com/plotty@0.2.0/src/plotty.js"></script>
  <script src="leaflet-geotiff.js"></script>
  <!-- Load any renderer you need -->
  <script src="leaflet-geotiff-plotty.js"></script>
  <script src="leaflet-geotiff-vector.js"></script>
  <script src="http://unpkg.com/leaflet-sidebar-v2"></script>

  <div id="map"></div>

  <script src="leaflet-heat.js"></script>
</div>
