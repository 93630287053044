import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(private http: HttpClient) { }


  // url = 'http://localhost:5000/api/locations'

  postdataURL = 'http://localhost:8080/api/SynData1:';
  getLocation() {
    var url = 'https://getafix.southafricanorth.cloudapp.azure.com/syng2/api/locations';
    return this.http.get(url);

  }

  getData(index) {
    console.log(index);
    // var url = 'http://localhost:5000/api/getGraph/' + index;
    // return this.http.get(url);
  }



  getMetrics() {
    var url = 'https://getafix.southafricanorth.cloudapp.azure.com/syng2/api/getRasterMetrics';
    return this.http.get(url);
  }
  getRaster(DataName, Date) {
    // console.log(Date, DataName);

    this.postdataURL = 'https://getafix.southafricanorth.cloudapp.azure.com/syng2/api/raster/' + DataName + '/' + Date;
    // console.log(this.postdataURL);
    return this.http.get(this.postdataURL);
  }
}
